@import '../style/colors.scss';
@import '../style/variables.scss';

.item {
	padding: 50px 0;
	background: $secondaryColor;

	@media screen and (max-width: $maxSm) {
		padding: 20px 0;
	}

	&__body {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;

		@media screen and (max-width: $maxSm) {
			flex-direction: column-reverse !important;
		}

		&-image {
			padding: 0 15px;
			flex: 1;

			img {
				max-width: 100%;
				height: auto;
			}

			// На малых экранах при вертикальном расположении добавляем вертикальные отступы
			@media screen and (max-width: $maxSm) {
				padding: 20px 0;
			}

			// На средних и малых экранах сжимаем картинку
			@media screen and (max-width: $maxMd) {
				flex: 0;
			}
			& > .marvel-device-scale {
				zoom: 0.7;
				transition: transform 0.3s ease-in;

				@media screen and (min-width: $minMd) and (max-width: $maxMd) {
					zoom: 0.6;
				}
				@media screen and (max-width: $maxSm) {
					zoom: 0.6;
				}
			}
		}

		&-information {
			display: flex;
			flex-direction: column;
			flex: 1;
			padding: 0 30px;
			text-align: left;

			@media screen and (max-width: $maxSm) {
				align-items: center;
				text-align: center;
				padding: 0;
			}

			&-header {
				display: flex;
				flex-direction: row;
				align-items: center;

				&-icon {
					font-size: 30px;
					margin-right: 20px;

					@media screen and (max-width: $maxMd) {
						font-size: 25px;
					}
				}

				&-text {
					font-size: 35px;
					margin-top: 7px;

					@media screen and (max-width: $maxMd) {
						font-size: 28px;
					}
				}
			}

			&-description {
				font-size: 18px;
				margin-top: 30px;
				color: $lightColor;
				font-weight: 400;

				@media screen and (max-width: $maxMd) {
					font-size: 17px;
				}
				@media screen and (max-width: $maxSm) {
					font-size: 16px;
					margin: 10px 0;
				}
			}
		}
	}

	// Реверсивный элемент - когда картинка справа
	&-reverse {
		.item__body {
			flex-direction: row-reverse;
		}
	}
}
