@import '../style/colors.scss';
@import '../style/variables.scss';

.ReactCollapse--collapse {
	transition: height 0.3s linear;
}

.faq-item {
	display: flex;
	flex-direction: column;
	border-bottom: 1px solid $secondaryColorDark;
	text-align: left;

	&__question {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		padding: 20px 0;
		font-size: 19px;
		cursor: pointer;

		@media screen and (max-width: $maxMd) {
			font-size: 17px;
			padding: 15px 0;
		}

		&-title {
			font-weight: 600;
			color: black;
		}

		&-icon img {
			margin: 10px;
			width: 19px;
			transition: transform 0.3s ease;
		}
	}

	&__answer {
		margin-right: 35px;
		padding: 0 0 20px;
		color: $lightColor;
		font-weight: 300;
		font-size: 16px;

		@media screen and (max-width: $maxMd) {
			font-size: 14px;
			margin-right: 0;
		}
	}
}

.selected {
	transform: rotate(180deg);
}
