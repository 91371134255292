@import '../style/colors.scss';
@import '../style/variables.scss';

.advantages {
	display: flex;
	flex-direction: column;
	padding-top: 50px;

	h1 {
		font-size: 40px;
		margin-bottom: 30px;
	}

	&__container {
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
		justify-content: space-between;

		// Для средних экранов (2 столбика) объединим их в центре
		@media screen and (min-width: $maxSm) {
			justify-content: space-around;
		}

		i {
			width: 40px;
			height: 40px;
			margin-right: 20px;
			margin-top: 6px;
		}

		&-item {
			display: flex;
			flex-direction: row;
			flex: 1 1 300px;
			padding: 25px;
			margin: 20px 15px;

			border-radius: 8px;
			box-shadow: 5px 5px 20px 0px rgba(42, 82, 148, 0.1);
			transition: 0.3s ease;

			&:hover {
				transform: translateY(-10px);
			}

			// Для больших экранов ограничим ширину компонентов
			@media screen and (min-width: $maxSm) {
				max-width: 350px;
			}
			@media screen and (min-width: $minLg) and (max-width: $maxLg) {
				max-width: none;
			}
			@media screen and (max-width: $maxXs) {
				margin-left: 10px;
				margin-right: 10px;
			}

			&-text {
				display: flex;
				flex-direction: column;

				&-title {
					font-size: 20px;
					font-weight: bold;
					text-align: left;
				}

				&-description {
					display: flex;
					margin-top: 5px;
					text-align: justify;
					min-height: 100px;
					color: #888;
					font-weight: 300;
					font-size: 15px;
					line-height: 1.4;

					@media screen and (max-width: $maxSm) {
						min-height: 0;
					}
				}
			}
		}
	}
}
