@import '../style/colors.scss';
@import '../style/variables.scss';

.hero {
	padding-top: 140px;
	margin-bottom: 100px;

	// На маленьком экране не нужен отступ сверху, т.к. нет заголовка
	@media screen and (max-width: $maxSm) {
		padding-top: 0;
	}

	background: linear-gradient(rgba($primaryColor, 0.8), rgba($primaryColor, 0.8)), url('/assets/hero-background.jpg');
	background-attachment: fixed !important;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;

	&__body {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;

		// На маленьких экранах картинку и текст располагаем вертикально
		@media screen and (max-width: $maxSm) {
			flex-direction: column;
			padding-top: 80px;
			padding-bottom: 80px;
		}

		&-image {
			margin-bottom: -100px;
			padding-right: 15px;
			padding-left: 15px;
			flex: 1;

			// На маленьких экранах отступы картинки чуть больше, чтобы она не занимала много места
			@media screen and (max-width: $maxSm) {
				margin-left: 10%;
				margin-right: 10%;
				margin-top: 50px;
				margin-bottom: -150px;
				order: 2;
			}

			img {
				max-width: 100%;
				height: auto;
			}

			& > .marvel-device-scale {
				zoom: 0.8;

				@media screen and (min-width: $minMd) and (max-width: $maxMd) {
					zoom: 0.7;
					transition: transform 0.3s ease-in;
				}
				@media screen and (max-width: $maxSm) {
					zoom: 0.6;
				}
				@media screen and (max-width: $maxXs) {
					zoom: 0.5;
				}
			}
		}

		&-title {
			color: #fff;
			margin: 0 auto;
			padding-right: 15px;
			padding-left: 15px;
			flex: 1;
			width: 100%;
			order: 1;

			&-name {
				font-size: 52px;
				font-weight: bold;
				padding-bottom: 20px;
			}

			&-description {
				font-size: 20px;
				margin-top: 20px;
				text-align: justify;
			}

			&-actions {
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: center;
				flex-wrap: wrap;
				margin: 40px 0;

				@media screen and (max-width: $maxSm) {
					margin-bottom: 0;
				}
			}
		}
	}
}
