@import '../../style/colors.scss';
@import '../../style/variables.scss';

.download-button {
	margin: 10px;

	img {
		width: 150px;
		height: 45px;

		// На средних экранах уменьшаем размер кнопок, чтобы они не растягивали текст
		@media screen and (min-width: $minMd) and (max-width: $maxMd) {
			width: 134px;
			height: 40px;
		}
	}
}
