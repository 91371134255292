@import './style/colors.scss';

.App {
	text-align: center;
	overflow: hidden;
}

section:before {
	height: 54px;
	width: 100%;
	content: '';
	display: block;
}

html {
	scroll-behavior: smooth;
}

::-webkit-scrollbar {
	width: 10px;
}
::-webkit-scrollbar-track {
	background: transparent;
}
::-webkit-scrollbar-thumb {
	background: $primaryColor;
}
::-webkit-scrollbar-thumb:hover {
	background: $primaryColorDisabled;
}
