@import '../style/colors.scss';
@import '../style/variables.scss';

.getting-started {
	background: linear-gradient(rgba($primaryColor, 0.8), rgba($primaryColor, 0.8)), url('/assets/getting-started-background.jpg');
	background-attachment: fixed !important;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;

	&__body {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;

		&-image {
			padding-right: 15px;
			padding-left: 15px;
			flex: 1;
			margin-left: 10%;

			// На маленьких экранах отступы картинки чуть больше, чтобы она не занимала много места
			@media screen and (max-width: $maxMd) {
				display: none;
			}

			& > .marvel-device-scale {
				position: relative;
				margin-top: -50px;
				top: 50px;
				zoom: 0.6;
			}

			img {
				max-width: 100%;
				height: auto;
			}
		}

		&-text {
			padding: 40px 15px;
			color: #fff;
			margin: 0 auto;
			flex: 2;

			@media screen and (max-width: $minSm) {
				padding-right: 0;
				padding-left: 0;
			}

			&-title {
				font-size: 35px;
				font-weight: bold;
				line-height: 1;
			}

			&-description {
				font-size: 20px;
				margin-top: 30px;
				text-align: justify;
			}

			&-actions {
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: center;
				margin-top: 40px;
				flex-wrap: wrap;
			}
		}
	}
}
