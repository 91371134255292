@import '../style/colors.scss';
@import '../style/variables.scss';

.footer {
	display: flex;
	flex-direction: column;
	padding: 30px 0;
	color: #fff;

	background-color: #2a5294;
	background-image: linear-gradient(315deg, #334980 0%, #5472b8 74%);

	&__body {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		margin: 30px 0;

		@media screen and (max-width: $minMd) {
			text-align: center;
			flex-direction: column;

			&-info {
				align-items: center;
				margin: 10px 0;
			}
		}

		&-info {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			flex: 2;
			padding: 0 10px;

			img {
				width: 50px;
				height: 50px;
			}

			&-description {
				color: #fff;
				font-size: 14px;
				text-align: start;
			}

			a {
				display: flex;
				align-items: center;
				color: #fff;
				text-decoration: none;
				margin-bottom: 15px;

				span {
					font-size: 18px;
					line-height: 1.3;
				}
			}

			&-logo {
				color: white;
				margin-right: 15px;
			}

			@media screen and (max-width: $minMd) {
				justify-content: center;
			}
		}

		&-links {
			display: flex;
			flex-direction: row;
			flex: 2;

			@media screen and (max-width: $minLg) {
				flex: 3;
			}

			// На маленьких экранах столбики с данными располагаем в столбик
			@media screen and (max-width: $minMd) {
				flex-direction: column;

				// Все колонки располагаем в строку
				& > * {
					flex-direction: row !important;
					flex-wrap: wrap;
					justify-content: space-between;
					margin: 15px 0;

					& > * {
						flex: 1 1 200px;
					}
				}

				&-actions {
					justify-content: center;
					flex-direction: row;

					& > * {
						flex: 0;
						margin: 10px !important;
					}
				}
			}

			&-contents {
				display: flex;
				flex-direction: column;
				flex: 1;
				padding: 0 10px;
			}
			&-social {
				display: flex;
				flex-direction: column;
				flex: 1;
				padding: 0 10px;
			}

			&-contents a,
			&-social a {
				color: #fff;
				text-decoration: none;
				margin: 5px 0;
				font-weight: 500;
				display: inline-block;
				line-height: 1.2;

				// Анимация подчеркивания ссылки
				&:after {
					content: '';
					width: 0px;
					height: 1px;
					display: block;
					background: #fff;
					transition: 300ms;
				}
				&:hover:after {
					width: 100%;
				}
			}

			&-actions {
				display: flex;
				flex-direction: column;
				padding: 0 10px;

				&-item {
					margin: 10px 10px 0;
				}
			}
		}
	}

	&__copyright {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		border-top: 1px solid lightgray;
		padding-top: 30px;

		@media screen and (max-width: $minMd) {
			justify-content: center;
		}
	}
}
