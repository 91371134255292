@import '../style/colors.scss';
@import '../style/variables.scss';

.feedback {
	display: flex;
	flex: 1;
	flex-direction: row;
	align-items: flex-start;
	justify-content: space-around;
	height: 500px;

	@media screen and (max-width: $maxMd) {
		flex-direction: column;
		align-items: center;
		justify-content: center;
		margin-top: 140px;
		margin-bottom: 200px;
	}

	@media screen and (max-width: $maxSm) {
		margin-top: 280px;
		margin-bottom: 200px;
	}

	&__statistic {
		margin-top: 20px;
		display: flex;
		flex-direction: column;
		flex: 1;
		align-items: center;
		justify-content: space-between;

		&-title {
			font-size: 25px;
			font-weight: bold;
		}

		&-list {
			margin-top: 10px;

			&-item {
				color: white;
				margin-top: 20px;
				border: 2px solid $primaryColor;
				padding-left: 30px;
				padding-right: 30px;
				background: #4cceb2;
				background: -webkit-radial-gradient(#4cceb2, #2f8fd8);
				background: -o-radial-gradient(#4cceb2, #2f8fd8);
				background: -moz-radial-gradient(#4cceb2, #2f8fd8);
				background: radial-gradient(#4cceb2, #2f8fd8);

				&-title {
					font-size: 30px;
				}

				&-value {
					font-size: 40px;
				}
			}
		}
	}

	&__reviews {
		flex: 1;
		margin-top: 20px;
		width: 60%;
		@media screen and (max-width: $maxMd) {
			width: 90%;
		}

		&-title {
			padding-bottom: 40px;
			font-size: 25px;
			font-weight: bold;
		}

		&-carousel {
			&-item {
				background-color: white;
				height: 350px;
				border-radius: 50px;
				opacity: 0.3;

				@media screen and (max-width: $maxMd) {
					height: 250px;
				}

				&-header {
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: flex-start;
					margin-top: 10px;
					color: $primaryColor;

					@media screen and (max-width: $maxMd) {
						margin-bottom: -20px;
					}

					&-photo {
						margin-left: 30px;
						margin-right: 20px;

						img {
							width: 120px;
							height: 120px;
							border-radius: 60px;

							@media screen and (max-width: $maxMd) {
								width: 60px;
								height: 60px;
								border-radius: 30px;
							}
						}
					}

					&-name {
						font-size: 25px;

						@media screen and (max-width: $maxMd) {
							font-size: 20px;
						}
					}
				}

				&-review {
					font-size: 20px;

					@media screen and (max-width: $maxMd) {
						margin-top: 30px;
						font-size: 15px;
					}
				}
			}
		}
	}
}

.slick-slide.slick-center {
	.feedback__reviews-carousel-item {
		opacity: 1;
	}
}
