@import '../style/colors.scss';
@import '../style/variables.scss';

.privacy {
	padding-bottom: 30px;

	&__title {
		padding-top: 120px;
		font-size: 40px;
		
		@media screen and (max-width: $maxSm) {
			font-size: 30px;
		}
	}

	&__body {
		background-color: #fff;
		padding-top: 15px;
		padding-bottom: 15px;

		&-item {
			padding-left: 25px;
			padding-top: 15px;
			text-align: left;
			font-size: 20px;
		}
	}
}
