@import '../style/colors.scss';
@import '../style/variables.scss';

.faq {
	display: flex;
	flex-direction: column;
	background-color: $secondaryColor;
	padding: 50px 0 20px;

	&__title {
		&-header {
			word-break: keep-all;
			font-size: 35px;
			font-weight: bold;
			line-height: 1;

			@media screen and (max-width: $maxSm) {
				font-size: 25px;
			}
		}

		&-description {
			margin-top: 20px;
			font-size: 20px;
			font-weight: 300;
			color: $lightColor;

			@media screen and (max-width: $maxSm) {
				font-size: 15px;
				padding: 0;
			}
		}
	}

	&__items {
		margin-top: 30px;
	}

	&__contacts {
		margin: 30px 0;

		&-title {
			word-break: keep-all;
			font-size: 35px;
			font-weight: bold;
			line-height: 1;

			@media screen and (max-width: $maxSm) {
				font-size: 25px;
			}
		}

		&-items {
			margin-top: 25px;
			&-item {
				display: flex;
				flex-direction: row;
				justify-content: center;

				@media screen and (max-width: $maxMd) {
					justify-content: center;
				}

				font-size: 20px;
				font-weight: 600;

				&-name {
					color: $lightColor;
				}

				&-value {
					margin-left: 5px;

					// Анимация подчеркивания ссылки
					& a {
						text-decoration: none;

						&:after {
							content: '';
							width: 0px;
							height: 1px;
							display: block;
							background: #000;
							transition: 300ms;
						}
						&:hover:after {
							width: 100%;
						}
					}
				}

				a {
					color: black;
				}
			}
		}
	}
}
