@import '../style/colors.scss';
@import '../style/variables.scss';

.navbar {
	padding: 40px 0;
	background-color: transparent;
	transition: all 400ms ease-in-out;

	// Когда проскроллили вниз, заголовок становится белым и маленьким
	&.scroll {
		padding: 20px;
		background-color: #fcfcfc;
		box-shadow: 0 0 2px rgba(50, 50, 50, 0.4);
	}

	&-toggler {
		border: none;
	}

	@media (max-width: $maxSm) {
		background-color: #fff !important;
		padding: 20px;
	}

	&-brand {
		color: $primaryColor !important;
		font-size: 25px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;

		@media (min-width: $minMd) {
			color: white !important;
		}

		&-icon {
			width: 50px;
			height: 50px;
			margin-right: 15px;
		}
		&-text {
			line-height: 0.8;
		}
	}
}

.nav-item {
	margin: 0 15px;
}
.nav-link {
	font-size: 0.835rem;
	font-weight: 500;
	text-transform: uppercase;
	padding: 10px 20px !important;
	border-radius: 6px;

	@media (min-width: $minMd) {
		color: #f0f0f0 !important;
	}

	&:hover {
		background: rgba(255, 255, 255, 0.2);
		color: #f0f0f0;
		transition: 0.5s ease;
	}
}

// Когда проскроллили вниз, заголовок становится белым
.scroll {
	& .nav-link {
		color: $primaryColor !important;

		&:hover {
			background: rgba($primaryColor, 0.2);
		}
	}
}
.navbar.scroll .navbar-brand {
	color: $primaryColor !important;
}

.language-selector {
	& > div {
		margin: 0 auto;
	}

	& .react-select__indicator {
		@media (max-width: $minMd) {
			color: $primaryColor !important;
		}
	}
}
